import React from "react";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";
import Numbers from "../../components/numbers";
import IndividualPlan from "../../components/individualPlan";

import { Link } from "gatsby";

const AboutFirm = () => {
  const lang = "en";

  return (
    <Layout
      seoTitle="About us"
      lang={lang}
      translationPL="/o-firmie/"
      translationDE="/de/uber-uns/"
    >
      <SmallBanner title="About us" lang={lang} />
      <section className="firm-info">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
              <h2 className="color--main mb-40">
                Company <br /> information
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
              <p className="mb-100 firm-info__left-padding">
                PalettenWerk was established in 1990 and is currently one of the
                largest manufacturers and exporters of wooden pallets in Poland.
                The company’s nearly 30 years in business have provided
                extensive experience in the production and delivery of pallets
                for virtually every type of industry. The pallets manufactured
                by PalettenWerk are used by several hundred companies from the
                food, construction, chemical, pharmaceutical, brewing, metal,
                and other industries.
              </p>
              <img
                className="firm-info__left-padding"
                src={require("../../assets/img/firm-info1.jpg")}
                alt="Firm info"
              />
            </div>
          </div>
          <div className="row firm-info__center-row">
            <div className="col-12 col-lg-6 col-xl-4">
              <div
                className="center-row__image"
                style={{
                  backgroundImage: `url(${require("../../assets/img/firm-info2.jpg")})`,
                }}
              ></div>
            </div>
            <div className="col-12 col-lg-6 col-xl-8">
              <p className="firm-info__left-padding">
                Every month, 900 HGVs delivering pallets to customers in Poland
                and abroad leave the company’s plants. The quality and customer
                service standards at PalettenWerk allow us to meet the
                expectations of even the most demanding domestic and foreign
                customers. PalettenWerk’s customers include the largest concerns
                and corporations, and 60% of production is exported to over 15
                European countries.
              </p>
            </div>
          </div>
          <div className="row firm-info__last-row">
            <div className="col-12 col-lg-6 col-xl-8">
              <p>
                In order to ensure quality and to optimise production and
                logistics, PalettenWerk carries out a number of activities. They
                consist of a quality management system, an ordering and supply
                management system, a raw material management system, a material
                flow support system, and a daily loss monitoring programme.
              </p>
            </div>
            <div className="col-12 col-lg-6 col-xl-4">
              <img
                className="last-row__image"
                src={require("../../assets/img/firm-info3.jpg")}
                alt="Firm info"
              />
            </div>
          </div>
        </div>
      </section>
      <Numbers lang={lang} />
      <section className="timeline" id="30-years-on-the-market">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
              <h2 className="color--main mb-20">30 years on the market</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
              <p className="mb-100">
                Our history goes back to 1990. In 30 years, our company has
                grown from a small trading company to one of the largest pallet
                manufacturers in Europe, supplying over 7 million pallets per
                year. This is possible thanks to continuous investment in
                modernisation, which optimises the production, management, and
                sales of our products. Despite reaching such a high level of
                output, the company still retains the character of a family
                business, combining tradition with modern company management
                techniques. Thanks to this, we can ensure a friendly working
                environment, where all employees feel comfortable – many of them
                have been working with us since our company was established. The
                past 30 years are a good starting point for further growth and
                for tackling the challenges of a changing economy.
              </p>
            </div>
          </div>
        </div>
        <div className="timeline__container">
          <div className="timeline__holder">
            <div className="timeline__item">
              <p className="item__date xxl color--main">1990</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../../assets/img/timeline1.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
                Our history begins in 1990, when we started making our first
                pallet deliveries to the German market. These were mostly
                pallets purchased from small production plants.
              </p>
            </div>
            <div className="timeline__item">
              <p className="item__date xxl color--main">1992</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../../assets/img/timeline2.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
                In 1992 we started producing our own products, in Bystra
                Podhalańska – the first location of our company. We produced
                only EURO-pallets on a second-hand automatic production line
                purchased abroad.
              </p>
            </div>
            <div className="timeline__item">
              <p className="item__date xxl color--main">1995</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../../assets/img/timeline3.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
                In 1995 we were one of the first plants in Poland to install a
                dedicated line for the production of non-standard pallets,
                enabling us to meet our customers’ needs in a comprehensive way
                and to make optimal use of the raw materials.
              </p>
            </div>
            <div className="timeline__item">
              <p className="item__date xxl color--main">1998</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../../assets/img/timeline4.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
                In 1998 we acquired a site in Gniewięcin, Świętokrzyskie
                voivodship, where Palko sp. z o.o., an EPAL pallet manufacturer,
                had been established.
              </p>
            </div>
            <div className="timeline__item">
              <p className="item__date xxl color--main">2000</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../../assets/img/timeline5.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
                In 2000 we purchased land in Jordanów and moved our company to a
                new location. On a new, much larger area, a modern production
                hall with an area of over 3000 m2, a complex of drying
                facilities and a new EPAL pallet production line were built.
              </p>
            </div>
            <div className="timeline__item">
              <p className="item__date xxl color--main">2000-2020</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../../assets/img/timeline6.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
                Between 2000 and 2020, large investments were made in machinery,
                sheltered storage spaces, and modern drying rooms and ovens. We
                purchased three Erba OMEV automatic pallet production lines, two
                CAPE production lines, and two automatic production lines for
                manufacturing DHP pallets, among other upgrades.
              </p>
            </div>
            <div className="timeline__item">
              <p className="item__date xxl color--main">2018</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../../assets/img/timeline7.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
                In 2018, we started production at our new plant in Chervonohrad,
                Ukraine, from where we supply pallets to Ukraine and
                neighbouring countries.
              </p>
            </div>
            <div className="timeline__item">
              <p className="item__date xxl color--main">2020</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../../assets/img/timeline8.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
                Currently, the PalettenWerk Group has 30 ha of sheltered
                warehouse space, 26 modern drying rooms with heat recuperation,
                and 9 fully automatic production lines.
              </p>
            </div>
            <div className="timeline__end-element"></div>
          </div>
        </div>
      </section>
      <IndividualPlan
        title="Why us?"
        text="Our enormous production and storage potential and full logistics service make us a leader in the pallet manufacturing industry in Poland and Europe. We are prepared to meet the expectations of the most demanding customers in every respect. This is thanks to decades of experience, a team of qualified people, technical and logistical facilities, and continuous efforts to improve our work. The high quality of our products is confirmed by our numerous certificates, including an EPAL licence (for all types of pallets), a CP licence (Chemical Pallets, all types), an ISPM15 certificate, an ISO 9001:2015 Quality Certificate, a PEFC certificate, AEO authorisation, and others. Our principle is that our customers are our greatest asset."
        lang={lang}
      />
      <section className="firm-info-ecology">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
              <h2 className="color--main">Environmental protection</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
              <p className="mb-100">
                PalettenWerk is committed to maintaining a reasonable, safe
                impact on the environment. PalattenWerk’s environmental policy
                covers the efficient and economical use of raw materials and
                energy and the minimisation of the negative environmental impact
                of production processes.
              </p>
            </div>
            <div className="col-12 offer-quality__btn-holder">
              <Link
                className="btn"
                to="/en/environmental-protection/"
                data-hover="Learn more"
              >
                Learn more
                <span className="btn-arrows"></span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AboutFirm;
